import { AudioManager } from "../../AudioManager";
import { BoardManager } from "./BoardManager";
import { GameManager } from "./../../GameManager";
import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { Button } from "../../utils/Utils";

export class PauseMenu extends Phaser.GameObjects.Container {

    private btnSoundOn: Button;
    private btnSoundOff: Button;

    private midContainer: Phaser.GameObjects.Container;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.x = GameConstants.GAME_WIDTH / 2;
        this.y = GameConstants.GAME_HEIGHT / 2;
        this.scaleY = GameVars.scaleY;

        const bck = new Phaser.GameObjects.Graphics(this.scene);
        bck.fillStyle(0x000000, .5);
        bck.fillRect(-GameConstants.GAME_WIDTH / 2, -(GameConstants.GAME_HEIGHT / GameVars.scaleY) / 2, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT / GameVars.scaleY);
        bck.setInteractive(new Phaser.Geom.Rectangle(-GameConstants.GAME_WIDTH / 2, -(GameConstants.GAME_HEIGHT / GameVars.scaleY) / 2, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT / GameVars.scaleY), Phaser.Geom.Rectangle.Contains);
        bck.on("pointerdown", () => { 
            // do nothing
        });
        this.add(bck);

        this.midContainer = new Phaser.GameObjects.Container(this.scene);
        this.add(this.midContainer);

        const imgBck = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "popup_layout");
        this.midContainer.add(imgBck);

        const imgTitle = new Phaser.GameObjects.Image(this.scene, 0, -283, "texture_atlas_1", "txt_menu");
        this.midContainer.add(imgTitle);

        const exitButton = new Button(this.scene, 230, -250, "texture_atlas_1", "btn_close", "btn_close_hover");
        exitButton.onDown(() => {
            BoardManager.unPauseGame();
            AudioManager.playSound("btn_pressed");
        }, this);
        exitButton.setScale(.6);
        this.midContainer.add(exitButton);

        this.btnSoundOn = new Button(this.scene, -90, -60, "texture_atlas_1", "btn_sound_on", "btn_sound_on_hover");
        this.btnSoundOn.onDown(this.onSoundOn, this);
        this.btnSoundOn.setScale(.6);
        this.btnSoundOn.visible = !GameVars.gameData.muted;
        this.midContainer.add(this.btnSoundOn);

        this.btnSoundOff = new Button(this.scene, -90, -60, "texture_atlas_1", "btn_sound_off", "btn_sound_off_hover");
        this.btnSoundOff.onDown(this.onSoundOff, this);
        this.btnSoundOff.setScale(.6);
        this.btnSoundOff.visible = GameVars.gameData.muted;
        this.midContainer.add(this.btnSoundOff);

        let text = new Phaser.GameObjects.Text(this.scene, -20, -60, "SOUND", {fontFamily: "Teko", fontSize: "50px", color: "#ABEAFF"});
        text.setShadow(0, 0, " #00FFFF", 5);
        text.setOrigin(0, .5);
        this.midContainer.add(text);

        const restartButton = new Button(this.scene, -90, 90, "texture_atlas_1", "btn_restart", "btn_restart_hover");
        restartButton.setScale(.6);
        restartButton.onDown(() => {
            AudioManager.playSound("btn_pressed");
            GameManager.resetGame();
        }, this);
        this.midContainer.add(restartButton);

        text = new Phaser.GameObjects.Text(this.scene, -20, 90, "RESTART", {fontFamily: "Teko", fontSize: "50px", color: "#ABEAFF"});
        text.setShadow(0, 0, " #00FFFF", 5);
        text.setOrigin(0, .5);
        this.midContainer.add(text);

        this.visible = false;
    }

    public show(): void {

        this.alpha = 0;
        this.midContainer.setScale(.8, .8)
        this.visible = true;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });

        this.scene.tweens.add({
            targets: this.midContainer,
            scaleY: 1,
            scaleX: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });
    }

    public hide(): void {

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            ease: Phaser.Math.Easing.Linear,
            duration: 250,
            onComplete: () => {
                this.visible = false;
            },
            onCompleteScope: this

        });
    }

    private onSoundOn(): void {

        AudioManager.playSound("btn_pressed");

        this.btnSoundOn.visible = false;
        this.btnSoundOff.visible = true;

        AudioManager.toggleAudioState();
    }

    private onSoundOff(): void {

        AudioManager.playSound("btn_pressed");

        this.btnSoundOn.visible = true;
        this.btnSoundOff.visible = false;

        AudioManager.toggleAudioState();
    }
}
