import { GameConstants } from "./../../../GameConstants";
import { GameVars } from "../../../GameVars";
export class LevelContainer extends Phaser.GameObjects.Container {

    private imagesText: Phaser.GameObjects.Image[];
    private imageNumber: Phaser.GameObjects.Image;
    private imageNumber2: Phaser.GameObjects.Image;

    private text = ["l", "e", "v", "e", "l"];

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.imagesText = [];

        for (let i = 0; i < 5; i++) {
            let imgText = new Phaser.GameObjects.Image(this.scene, -340, -320 + i * 50, "texture_atlas_1", this.text[i] + "_" + GameConstants.COLOURS[GameVars.level % 3]);
            this.add(imgText);
            this.imagesText.push(imgText);
        }

        this.imageNumber = new Phaser.GameObjects.Image(this.scene, -340, -45, "texture_atlas_1", GameConstants.COLOURS[GameVars.level % 3] + "_" + GameVars.level);
        this.add(this.imageNumber);

        this.imageNumber2 = new Phaser.GameObjects.Image(this.scene, -340, -45, "texture_atlas_1");
        this.imageNumber2.visible = false;
        this.add(this.imageNumber2);
    }

    public updateLevel(): void {

        for (let i = 0; i < 5; i++) {
            this.scene.tweens.add({
                targets: this.imagesText[i],
                scaleY: 0,
                ease: Phaser.Math.Easing.Cubic.In,
                duration: 75,
                delay: i * 100,
                onComplete: () => {
                    this.imagesText[i].setFrame(this.text[i] + "_" + GameConstants.COLOURS[GameVars.level % 3]);
                    this.scene.tweens.add({
                        targets: this.imagesText[i],
                        scaleY: 1,
                        ease: Phaser.Math.Easing.Cubic.Out,
                        duration: 75
                    });
                },
                onCompleteScope: this
            });
        }

        this.scene.tweens.add({
            targets: [this.imageNumber, this.imageNumber2],
            scaleY: 0,
            ease: Phaser.Math.Easing.Cubic.In,
            duration: 75,
            delay: 5 * 100,
            onComplete: () => {
                if (GameVars.level >= 10) {
                    this.imageNumber2.visible = true;
                    this.imageNumber2.x = -360;
                    this.imageNumber.x = -320;
        
                    this.imageNumber2.setFrame(GameConstants.COLOURS[GameVars.level % 3] + "_" + Math.floor(GameVars.level / 10));
                    this.imageNumber.setFrame(GameConstants.COLOURS[GameVars.level % 3] + "_" + GameVars.level % 10);
                } else {
                    this.imageNumber.setFrame(GameConstants.COLOURS[GameVars.level % 3] + "_" + GameVars.level);
                }
                this.scene.tweens.add({
                    targets: [this.imageNumber, this.imageNumber2],
                    scaleY: 1,
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 75
                });
            },
            onCompleteScope: this
        });
    }
}
