import { AudioManager } from "../../AudioManager";
import { GameOverLayer } from "./GameOverLayer";
import { PauseMenu } from "./PauseMenu";
import { GameConstants } from "./../../GameConstants";
import { GameManager } from "../../GameManager";
import { HUD } from "./hud/HUD";
import { GUI } from "./GUI";
import { BoardManager } from "./BoardManager";
import { BoardContainer } from "./board-container/BoardContainer";
import { GameVars } from "../../GameVars";
import { Tetromino } from "./board-container/Tetromino";
import { BackgroundContainer } from "./BackgroundContainer";

export class BoardScene extends Phaser.Scene {

    public static currentInstance: BoardScene;

    public f: number;

    private gui: GUI;
    private hud: HUD;
    private boardContainer: BoardContainer;
    private backgroundContainer: BackgroundContainer;
    private pauseMenu: PauseMenu;
    private fps: Phaser.GameObjects.Text;
    
    constructor() {

        super("BoardScene");
        
        BoardScene.currentInstance = this;
    }

    public create(): void {

        AudioManager.setLoopVolume(0);

        GameManager.setCurrentScene(this);

        BoardManager.init();

        this.f = 0;

        this.cameras.main.setBackgroundColor("#051429");

        this.backgroundContainer = new BackgroundContainer(this);
        this.add.existing(this.backgroundContainer);
        
        this.hud = new HUD(this);

        this.gui = new GUI(this);

        this.boardContainer = new BoardContainer(this);

        this.add.existing(this.boardContainer);
        this.add.existing(this.hud);
        this.add.existing(this.gui);

        this.pauseMenu = new PauseMenu(this);
        this.add.existing(this.pauseMenu);

        if (GameConstants.DEVELOPMENT) {
            this.fps = new Phaser.GameObjects.Text(this, GameConstants.GAME_WIDTH - 10, 10, Math.round(this.game.loop.actualFps).toString(), {fontFamily: "Teko", fontSize: "40px", color: "#FFFFFF"});
            this.fps.setOrigin(1, 0);
            this.fps.scaleY = GameVars.scaleY;
            this.add.existing(this.fps);
        }

        this.cameras.main.fadeIn(300, 255, 255, 255);
    }

    public startGame(): void {

        AudioManager.playSound("soundtrack", true);
        AudioManager.setLoopVolume(.25);

        BoardScene.currentInstance.nextTetrominosVisible();
        this.boardContainer.startGame();
    }

    public update(): void {

        if (GameConstants.DEVELOPMENT) {
            this.fps.text = Math.round(this.game.loop.actualFps).toString();
        }

        if (GameVars.paused || GameVars.onAnimation) {
            return;
        }

        if (this.f++ >= GameVars.timeoutFall) {
            BoardManager.fall();
            this.f = 0;
        }

        this.boardContainer.update();
    }

    public nextTetrominosVisible(): void {

        this.hud.nextTetromino.setVisibles();
    }

    public showPauseMenu(): void {

        this.pauseMenu.show();
    }

    public hidePauseMenu(): void {

        this.pauseMenu.hide();
    }

    public showGameOver(): void {

        let gameOverLayer = new GameOverLayer(this);
        this.add.existing(gameOverLayer);
    }

    public updateScore(length: number): void {

        this.hud.updateScore();

        if (length) {
            this.backgroundContainer.showEffect(length);
        }
    }

    public updateLevel(): void {

        this.hud.updateLevel();
        this.boardContainer.updateLevel();
    }

    public showNextTetromino(): void {

        this.hud.showNextTetromino();
    }

    public getTetromino(): Tetromino {

        return this.boardContainer.getTetromino();
    }
}
