import { GameVars } from "./../../../GameVars";
export class FramesContainer extends Phaser.GameObjects.Container {

    public lightMidLeft: Phaser.GameObjects.Image;
    public lightMidRight: Phaser.GameObjects.Image;
    public lightTopLeft: Phaser.GameObjects.Image;
    public lightTopRight: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        let extraLines = Math.round((1 - GameVars.scaleY) * 300);

        // LATERAL

        let frame = new Phaser.GameObjects.Image(this.scene, 385, -415, "texture_atlas_1", "grid_lateral");
        frame.setOrigin(1, 0);
        this.add(frame);

        // TOP
        frame = new Phaser.GameObjects.Image(this.scene, 0, -540 - extraLines, "texture_atlas_1", "frame_top");
        frame.setOrigin(1, 0);
        this.add(frame);

        this.lightTopLeft = new Phaser.GameObjects.Image(this.scene, 0, -540 - extraLines, "texture_atlas_1", "light_top_green");
        this.lightTopLeft.setOrigin(1, 0);
        this.add(this.lightTopLeft);

        frame = new Phaser.GameObjects.Image(this.scene, 0, -540 - extraLines, "texture_atlas_1", "frame_top");
        frame.setOrigin(1, 0);
        frame.scaleX = -1;
        this.add(frame);

        this.lightTopRight = new Phaser.GameObjects.Image(this.scene, 0, -540 - extraLines, "texture_atlas_1", "light_top_green");
        this.lightTopRight.setOrigin(1, 0);
        this.lightTopRight.scaleX = -1;
        this.add(this.lightTopRight);

        for (let i = 0; i < extraLines; i++) {

            frame = new Phaser.GameObjects.Image(this.scene, -251, -540 + 156 - extraLines + i, "texture_atlas_1", "frame_top_tileable");
            frame.setOrigin(1, 0);
            this.add(frame);

            frame = new Phaser.GameObjects.Image(this.scene, 251, -540 + 156 - extraLines + i, "texture_atlas_1", "frame_top_tileable");
            frame.setOrigin(1, 0);
            frame.scaleX = -1;
            this.add(frame);
        }

        // MID

        frame = new Phaser.GameObjects.Image(this.scene, -252, 22, "texture_atlas_1", "frame_mid");
        frame.setOrigin(1, .5);
        this.add(frame);

        this.lightMidLeft = new Phaser.GameObjects.Image(this.scene, -252, 22, "texture_atlas_1", "light_mid_green");
        this.lightMidLeft.setOrigin(1, .5);
        this.add(this.lightMidLeft);

        frame = new Phaser.GameObjects.Image(this.scene, 252, 22, "texture_atlas_1", "frame_mid");
        frame.setOrigin(1, .5);
        frame.scaleX = -1;
        this.add(frame);

        this.lightMidRight = new Phaser.GameObjects.Image(this.scene, 252, 22, "texture_atlas_1", "light_mid_green");
        this.lightMidRight.setOrigin(1, .5);
        this.lightMidRight.scaleX = -1;
        this.add(this.lightMidRight);

        // BOTTOM
        frame = new Phaser.GameObjects.Image(this.scene, 0, 428 + extraLines, "texture_atlas_1", "frame_bottom");
        frame.setOrigin(1, 0);
        this.add(frame);

        frame = new Phaser.GameObjects.Image(this.scene, 0, 428 + extraLines, "texture_atlas_1", "frame_bottom");
        frame.setOrigin(1, 0);
        frame.scaleX = -1;
        this.add(frame);

        for (let i = 0; i < extraLines; i++) {

            frame = new Phaser.GameObjects.Image(this.scene, -251, 428 + extraLines - i, "texture_atlas_1", "frame_mid_tileable");
            frame.setOrigin(1, 0);
            this.add(frame);

            frame = new Phaser.GameObjects.Image(this.scene, 251, 428 + extraLines - i, "texture_atlas_1", "frame_mid_tileable");
            frame.setOrigin(1, 0);
            frame.scaleX = -1;
            this.add(frame);
        }

        this.lightMidLeft.visible = false;
        this.lightMidRight.visible = false;
        this.lightTopLeft.visible = false;
        this.lightTopRight.visible = false;
    }

    public updateLevel(): void {

        if (GameVars.level % 3 === 0) {
            this.lightMidLeft.visible = true;
            this.lightMidRight.visible = true;
            this.lightTopLeft.visible = true;
            this.lightTopRight.visible = true;

            this.lightMidLeft.setFrame("light_mid_pink");
            this.lightMidRight.setFrame("light_mid_pink");
            this.lightTopLeft.setFrame("light_top_pink");
            this.lightTopRight.setFrame("light_top_pink");
        } else if (GameVars.level % 3 === 1) {
            this.lightMidLeft.visible = false;
            this.lightMidRight.visible = false;
            this.lightTopLeft.visible = false;
            this.lightTopRight.visible = false;
        } else {
            this.lightMidLeft.visible = true;
            this.lightMidRight.visible = true;
            this.lightTopLeft.visible = true;
            this.lightTopRight.visible = true;

            this.lightMidLeft.setFrame("light_mid_green");
            this.lightMidRight.setFrame("light_mid_green");
            this.lightTopLeft.setFrame("light_top_green");
            this.lightTopRight.setFrame("light_top_green");
        }
    }
}
