import { GameVars } from "./../../../GameVars";

export class NextTetromino extends Phaser.GameObjects.Container {

    private tetrominos: Phaser.GameObjects.Image[];

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        let next = new Phaser.GameObjects.Text(this.scene, 325, -286 - 32, "NEXT", {fontFamily: "Teko", fontSize: "30px", color: "#00FFFF"});
        next.setShadow(0, 0, "#ABEAFF", 5);
        next.setOrigin(.5);
        this.add(next);

        this.tetrominos = [];

        for (let i = 0; i < 3; i++) {
            let tetromino = new Phaser.GameObjects.Image(this.scene, 329, -227 - 32 + 100 * i, "texture_atlas_1");
            tetromino.visible = false;
            this.add(tetromino);
            this.tetrominos.push(tetromino);
        }
    }

    public setVisibles(): void {

        for (let i = 0; i < 3; i++) {
            this.tetrominos[i].visible = true;
            this.tetrominos[i].alpha = 0;
            this.scene.tweens.add({
                targets: this.tetrominos[i],
                alpha: 1,
                ease: Phaser.Math.Easing.Cubic.In,
                duration: 100,
            });
        }
    }

    public show(): void {

        for (let i = 0; i < 3; i++) {
            this.tetrominos[i].setFrame("next_figure_" + GameVars.nextTetrominoShapes[i]);
        }
    }
}