import { GameManager } from "../GameManager";
import { GameConstants } from "../GameConstants";
import { GameVars } from "../GameVars";
import { AudioManager } from "../AudioManager";
import { Howl } from "howler";

export class PreloadScene extends Phaser.Scene {

    public static currentInstance: PreloadScene;

    private progressBar: Phaser.GameObjects.Graphics;
    private scaledItemsContainer: Phaser.GameObjects.Container;
    private imageTap: Phaser.GameObjects.Image;

    constructor() {

        super("PreloadScene");

        PreloadScene.currentInstance = this;
    }

    public preload(): void {

        this.composeScene();

        this.loadAssets();
    }

    public create(): void {

        this.loadHowl();
    }
    
    private composeScene(): void {

        this.add.text(-100, -100, "abcdefg", { fontFamily: "Teko", fontSize: 28, color: "#A6F834" });

        this.scaledItemsContainer = this.add.container(GameConstants.GAME_WIDTH / 2, 560);
        this.scaledItemsContainer.scaleY = GameVars.scaleY;

        const loadIcon = new Phaser.GameObjects.Image(this, 0, 0, "load_icon");
        this.scaledItemsContainer.add(loadIcon);

        this.tweens.add({
            targets: loadIcon,
            angle: 360,
            ease: Phaser.Math.Easing.Linear,
            duration: 3500,
            repeat: -1
        });
    }

    private loadAssets(): void {

        this.load.image("bg", "assets/bg.jpg");

        this.load.atlas("texture_atlas_1", "assets/texture_atlas_1.png", "assets/texture_atlas_1.json");
        this.load.atlas("texture_atlas_2", "assets/texture_atlas_2.jpg", "assets/texture_atlas_2.json");
        
        this.load.bitmapFont("teko", "assets/fonts/bitmap/teko.png", "assets/fonts/bitmap/teko.xml");
        this.load.bitmapFont("teko-yellow", "assets/fonts/bitmap/teko-yellow.png", "assets/fonts/bitmap/teko-yellow.xml");

        // this.load.image("bg_splash", "assets/splash.jpg")

        this.load.json("audiosprite", "assets/audio/audiosprite.json");
    }

    private loadHowl(): void {

        let soundJson = PreloadScene.currentInstance.cache.json.get("audiosprite");
        soundJson = JSON.parse(JSON.stringify(soundJson).replace("urls", "src"));

        AudioManager.howl = new Howl(soundJson);

        AudioManager.howl.on("load", function() {
            // PreloadScene.currentInstance.showTap();
            PreloadScene.currentInstance.removeLoadBar();
            GameManager.onGameAssetsLoaded();
            PreloadScene.currentInstance.scene.setVisible(false);
        });
    }

    private removeLoadBar(): void {
        
        this.tweens.add({
            targets: this.scaledItemsContainer,
            alpha: 0,
            ease: Phaser.Math.Easing.Linear,
            duration: 500
        });
    }

    private showTap(): void {

        this.scaledItemsContainer.visible = false;

        this.imageTap = new Phaser.GameObjects.Image(this, GameConstants.GAME_WIDTH / 2, 560, "tap");
        this.imageTap.scaleY = GameVars.scaleY;
        this.add.existing(this.imageTap);

        this.tweens.add({
            targets: this.imageTap,
            alpha: .45,
            ease: Phaser.Math.Easing.Linear,
            duration: 1000,
            yoyo: true,
            repeat: -1
        });

        this.input.on("pointerdown", () => {
            this.input.removeAllListeners();
            GameManager.onGameAssetsLoaded();
        }, this);
    }
}
