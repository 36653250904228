export class GameConstants {

    public static readonly VERSION = "0.0";
    public static readonly DEVELOPMENT = false;
    public static readonly VERBOSE = false;
    public static readonly GAME_WIDTH = 768;
    public static readonly GAME_HEIGHT = 1024;

    public static readonly BOARD_WIDTH = 10;
    public static readonly BOARD_HEIGHT = 20;
    public static readonly BOARD_CELL_SIZE = 45;

    public static readonly STAR_PERCENTAGE = .75;
    public static readonly DOUBLE_STAR_PERCENTAGE = .25;

    public static readonly COLOURS = ["pink", "cian", "green"];

    public static readonly INIT_TIMEOUT_FALL = 42;

    public static readonly SAVED_GAME_DATA_KEY = "tetrix-mania-data";
}
