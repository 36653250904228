import { AudioManager } from './../AudioManager';
import { GameManager } from './../GameManager';
import { GameVars } from './../GameVars';
import { GameConstants } from '../GameConstants';
import { Button } from '../utils/Utils';
export class SplashScene extends Phaser.Scene {

    public static currentInstance: SplashScene;

    private playButton: Button;
    
    constructor() {

        super("SplashScene");
        
        SplashScene.currentInstance = this;
    }

    public create(): void {

        let midContainer = this.add.container(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT / 2);
        midContainer.scaleY = GameVars.scaleY;

        let bg = new Phaser.GameObjects.Image(this, 0, 0, "bg_splash");
        bg.alpha = 0;
        midContainer.add(bg);

        let bckTitleAux = new Phaser.GameObjects.Image(this, 0, -140, "texture_atlas_1", "splash_fx");
        bckTitleAux.alpha = 0;
        bckTitleAux.setScale(.9);
        midContainer.add(bckTitleAux);

        let bckTitle = new Phaser.GameObjects.Image(this, 0, -140, "texture_atlas_1", "splash_fx");
        bckTitle.alpha = 0;
        bckTitle.setScale(.9);
        bckTitle.blendMode = Phaser.BlendModes.OVERLAY;
        midContainer.add(bckTitle);

        let title = new Phaser.GameObjects.Image(this, 0, -190, "texture_atlas_1", "splash_logo_tetra");
        title.alpha = 0;
        title.setScale(.9);
        midContainer.add(title);

        let chars = ["b", "l", "o", "c", "k", "s"];

        for(let i = 0; i < chars.length; i++) {

            let char = new Phaser.GameObjects.Image(this, 0, -190, "texture_atlas_1", "splash_logo_" + chars[i]);
            char.alpha = 0;
            char.setScale(1.2);
            midContainer.add(char);

            this.tweens.add({
                targets: char,
                alpha: 1,
                scaleX: 1,
                scaleY: 1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 250,
                delay: 1000 + 150 * i,
                onStart: () => {
                    AudioManager.playSound("locked_piecemp3");
                },
                onStartScope: this
                
            });

            this.time.addEvent({ delay: 1000 + 150 * i + 200, callback: () => {
                let char2 = new Phaser.GameObjects.Image(this, 0, -190, "texture_atlas_1", "splash_logo_" + chars[i]);
                char2.blendMode = Phaser.BlendModes.SCREEN;
                midContainer.add(char2);

                this.tweens.add({
                    targets: char2,
                    alpha: 0,
                    ease: Phaser.Math.Easing.Cubic.In,
                    duration: 1000,
                });

                char2 = new Phaser.GameObjects.Image(this, 0, -190, "texture_atlas_1", "splash_logo_" + chars[i]);
                char2.blendMode = Phaser.BlendModes.SCREEN;
                midContainer.add(char2);

                this.tweens.add({
                    targets: char2,
                    alpha: 0,
                    ease: Phaser.Math.Easing.Cubic.In,
                    duration: 1000,
                });

                char2 = new Phaser.GameObjects.Image(this, 0, -190, "texture_atlas_1", "splash_logo_" + chars[i]);
                char2.blendMode = Phaser.BlendModes.SCREEN;
                midContainer.add(char2);

                this.tweens.add({
                    targets: char2,
                    alpha: 0,
                    ease: Phaser.Math.Easing.Cubic.In,
                    duration: 1000,
                });

                char2 = new Phaser.GameObjects.Image(this, 0, -190, "texture_atlas_1", "splash_logo_" + chars[i]);
                char2.blendMode = Phaser.BlendModes.SCREEN;
                midContainer.add(char2);

                this.tweens.add({
                    targets: char2,
                    alpha: 0,
                    ease: Phaser.Math.Easing.Cubic.In,
                    duration: 1000,
                });
            }, callbackScope: this});
        }

        this.playButton = new Button(this, 0, 40, "texture_atlas_1", "btn_play", "btn_play_hover");
        this.playButton.onDown(this.onClickPlay, this);
        this.playButton.alpha = 0;
        this.playButton.setScale(.5);
        midContainer.add(this.playButton);

        let glare = new Phaser.GameObjects.Image(this, 0, 40, "texture_atlas_1", "glare");
        glare.alpha = 0;
        glare.blendMode = Phaser.BlendModes.OVERLAY;
        midContainer.add(glare);

        this.tweens.add({
            targets: glare,
            angle: 360,
            ease: Phaser.Math.Easing.Linear,
            duration: 5000,
            repeat: -1
        });

        this.tweens.add({
            targets: glare,
            alpha: 1,
            ease: Phaser.Math.Easing.Linear,
            duration: 500,
            delay: 2500
        });

        this.tweens.add({
            targets: bg,
            scaleX: 1.1,
            scaleY: 1.1,
            ease: Phaser.Math.Easing.Cubic.InOut,
            duration: 5000,
            yoyo: true,
            repeat: -1
        });

        this.tweens.add({
            targets: bg,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.In,
            duration: 5000,
        });

        this.tweens.add({
            targets: title,
            alpha: 1,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 1000,
            delay: 500,
            onStart: () => {
                AudioManager.playSound("hard_drop");
            },
            onStartScope: this
        });

        this.tweens.add({
            targets: bckTitle,
            alpha: 1,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 1000,
            delay: 1000
        });

        this.tweens.add({
            targets: bckTitleAux,
            alpha: .2,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 1000,
            delay: 1000
        });

        this.tweens.add({
            targets: this.playButton,
            alpha: 1,
            scaleX: 1,
            scaleY: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500,
            delay: 2500
        });
    }

    private onClickPlay(): void {

        AudioManager.playSound("btn_pressed");

        this.scene.setVisible(false);
        this.playButton.input.enabled = false;
        GameManager.enterBoardScene();
    }
}
