import { BoardManager } from "./../BoardManager";
import { GameConstants } from "./../../../GameConstants";
import { AudioManager } from "../../../AudioManager";

export class Countdown extends Phaser.GameObjects.Container {

    private static blocks = {
        3:  [[2, 5], [3, 5], [4, 5], [5, 5], [6, 5], [7, 5], 
            [2, 6], [3, 6], [4, 6], [5, 6], [6, 6], [7, 6],
            [6, 7], [7, 7],
            [5, 8], [6, 8],
            [4, 9], [5, 9], [6, 9], [7, 9],
            [6, 10], [7, 10],
            [2, 11], [3, 11], [4, 11], [5, 11], [6, 11], [7, 11], 
            [3, 12], [4, 12], [5, 12], [6, 12]
        ],
        2:  [[3, 5], [4, 5], [5, 5], [6, 5], 
            [2, 6], [3, 6], [4, 6], [5, 6], [6, 6], [7, 6],
            [2, 7], [3, 7], [6, 7], [7, 7],
            [5, 8], [6, 8], [7, 8],
            [4, 9], [5, 9], [6, 9],
            [3, 10], [4, 10], [5, 10],
            [2, 11], [3, 11], [4, 11], [5, 11], [6, 11], [7, 11], 
            [2, 12], [3, 12], [4, 12], [5, 12], [6, 12], [7, 12]
        ],
        1:  [[3, 5], [4, 5], [5, 5], 
            [3, 6], [4, 6], [5, 6],
            [4, 7], [5, 7],
            [4, 8], [5, 8],
            [4, 9], [5, 9],
            [4, 10], [5, 10],
            [4, 11], [5, 11],
            [4, 12], [5, 12],
        ]
    };

    private blocks: Phaser.GameObjects.Image[];

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        const initX = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_WIDTH) / 2;
        const initY = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_HEIGHT) / 2;

        this.blocks = [];

        this.scene.time.addEvent({ delay: 500, callback: () => {
            AudioManager.playSound("countdowm-3-2");
        }, callbackScope: this});

        for (let i = 0; i < Countdown.blocks[3].length; i++) {

            let block = Countdown.blocks[3][i];

            this.scene.time.addEvent({ delay: 500 + 50 * (block[1] - 4), callback: () => {
                let img = new Phaser.GameObjects.Image(this.scene, initX + (block[0] + .5) * GameConstants.BOARD_CELL_SIZE, initY + (block[1] + .5) * GameConstants.BOARD_CELL_SIZE, "texture_atlas_1", "pixel_number");
                img.blendMode = Phaser.BlendModes.SCREEN;
                img.name = block[1].toString();
                this.blocks.push(img);
                this.add(img);
            }, callbackScope: this});
        }

        this.scene.time.addEvent({ delay: 1500, callback: () => {
            this.removeThree();
        }, callbackScope: this});
    }

    public removeThree(): void {

        AudioManager.playSound("countdowm-3-2");

        for (let i = 5; i <= 12; i++) {

            for (let j = 0; j < this.blocks.length; j++) {

                if (parseInt(this.blocks[j].name) === i) {

                    let block = this.blocks[j];
                    this.blocks.splice(j, 1);
                    j--;

                    this.scene.time.addEvent({ delay: 50 * (i - 5), callback: () => {
                        block.destroy();
                    }, callbackScope: this});
                } 
            }
        }

        const initX = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_WIDTH) / 2;
        const initY = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_HEIGHT) / 2;

        for (let i = 0; i < Countdown.blocks[2].length; i++) {

            let block = Countdown.blocks[2][i];

            this.scene.time.addEvent({ delay: 50 * (block[1] - 4), callback: () => {

                let img = new Phaser.GameObjects.Image(this.scene, initX + (block[0] + .5) * GameConstants.BOARD_CELL_SIZE, initY + (block[1] + .5) * GameConstants.BOARD_CELL_SIZE, "texture_atlas_1", "pixel_number");
                img.blendMode = Phaser.BlendModes.SCREEN;
                img.name = block[1].toString();
                this.blocks.push(img);
                this.add(img);
            }, callbackScope: this});
        }

        this.scene.time.addEvent({ delay: 1000, callback: () => {
            this.removeTwo();
        }, callbackScope: this});
    }

    public removeTwo(): void {

        AudioManager.playSound("countdowm-3-2");

        for (let i = 5; i <= 12; i++) {

            for (let j = 0; j < this.blocks.length; j++) {

                if (parseInt(this.blocks[j].name) === i) {

                    let block = this.blocks[j];
                    this.blocks.splice(j, 1);
                    j--;

                    this.scene.time.addEvent({ delay: 50 * (i - 5), callback: () => {
                        block.destroy();
                    }, callbackScope: this});
                } 
            }
        }

        const initX = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_WIDTH) / 2;
        const initY = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_HEIGHT) / 2;

        for (let i = 0; i < Countdown.blocks[1].length; i++) {

            let block = Countdown.blocks[1][i];

            this.scene.time.addEvent({ delay: 50 * (block[1] - 4), callback: () => {

                let img = new Phaser.GameObjects.Image(this.scene, initX + (block[0] + .5) * GameConstants.BOARD_CELL_SIZE, initY + (block[1] + .5) * GameConstants.BOARD_CELL_SIZE, "texture_atlas_1", "pixel_number");
                img.blendMode = Phaser.BlendModes.SCREEN;
                img.name = block[1].toString();
                this.blocks.push(img);
                this.add(img);
            }, callbackScope: this});
        }

        this.scene.time.addEvent({ delay: 1000, callback: () => {
            this.removeOne();
        }, callbackScope: this});
    }

    public removeOne(): void {

        AudioManager.playSound("countdowm-1");

        for (let i = 5; i <= 12; i++) {

            for (let j = 0; j < this.blocks.length; j++) {

                if (parseInt(this.blocks[j].name) === i) {

                    let block = this.blocks[j];
                    this.blocks.splice(j, 1);
                    j--;

                    this.scene.time.addEvent({ delay: 50 * (i - 5), callback: () => {
                        block.destroy();
                    }, callbackScope: this});
                } 
            }
        }

        this.scene.time.addEvent({ delay: 500, callback: () => {
            BoardManager.startGame();
        }, callbackScope: this});
    }
}
