import { Countdown } from "./Countdown";
import { LevelContainer } from "./LevelContainer";
import { NextTetromino } from "./NextTetromino";
import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";

export class HUD extends Phaser.GameObjects.Container {

    public nextTetromino: NextTetromino;

    private scoreLabel: Phaser.GameObjects.BitmapText;
    private scoreContainer: Phaser.GameObjects.Container;
    private levelContainer: LevelContainer;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.x = GameConstants.GAME_WIDTH / 2;
        this.y = GameConstants.GAME_HEIGHT / 2 + 32;
        this.scaleY = GameVars.scaleY;

        this.levelContainer = new LevelContainer(this.scene);
        this.add(this.levelContainer);

        let extraLines = Math.round((1 - GameVars.scaleY) * 300);

        this.scoreContainer = new Phaser.GameObjects.Container(this.scene, 0, -506 - extraLines);
        this.add(this.scoreContainer);

        this.scoreLabel = new Phaser.GameObjects.BitmapText(this.scene, 0, 0, "teko", GameVars.formatNumber(GameVars.score), 55);
        this.scoreLabel.setOrigin(.5);
        this.scoreContainer.add(this.scoreLabel);

        this.nextTetromino = new NextTetromino(this.scene);
        this.add(this.nextTetromino);

        let countdown = new Countdown(this.scene);
        this.add(countdown);
    }

    public showNextTetromino(): void {

        this.nextTetromino.show();
    }

    public updateScore(): void {

        this.scoreLabel.text = GameVars.formatNumber(GameVars.score);

        this.scoreContainer.setScale(1.15);

        this.scene.tweens.add({
            targets: this.scoreContainer,
            scaleX: 1,
            scaleY: 1,
            duration: 600,
            ease: Phaser.Math.Easing.Cubic.In
        });
    }

    public updateLevel(): void {

        this.levelContainer.updateLevel();
    }
}
