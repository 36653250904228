import { GameConstants } from "./GameConstants";
import { GameVars } from "./GameVars";
import { AudioManager } from "./AudioManager";
const { SimplioUserInfoSDK } = require('@SimplioOfficial/simplio-user-info-sdk');

export class GameManager {

    public static setViewPort(): void {

        let isMobile: any = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i);
        
        // POR EL ASUNTO DE LOS NUEVOS IPADS AIR
        if ((navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0) || navigator.platform === "iPad") {
            isMobile = true;
        }

        if (isMobile) {

            GameVars.aspectRatio = window.innerHeight / window.innerWidth;
            GameVars.scaleY = (GameConstants.GAME_HEIGHT / GameConstants.GAME_WIDTH) /  GameVars.aspectRatio;

            GameVars.isDesktop = false ;
            
        } else {

            GameVars.scaleY = 1;

            GameVars.isDesktop = true;
        }
    }

    public static init(): void {

        GameVars.intitialised = true;

        const urlParams = new URLSearchParams(window.location.search);

        GameVars.simplioApiUrl = urlParams.get("env") || "test";
        GameVars.simplioUserId = urlParams.get("userId") || "test";
        GameVars.simplioGameId = "7flvuW7LxeLLwHSyy-H3VOHHlCSXy4JQMbf4PkUNju_EAzFufaFKEQ==";

        GameVars.simplio = new SimplioUserInfoSDK(GameVars.simplioApiUrl, GameVars.simplioGameId);

        if (GameVars.currentScene.sys.game.device.os.desktop) {

            GameVars.scaleY = 1;

        } else {

            GameVars.currentScene.game.scale.displaySize = GameVars.currentScene.game.scale.parentSize;
            GameVars.currentScene.game.scale.refresh();

            const aspectRatio = window.innerHeight / window.innerWidth;
            GameVars.scaleY = (GameConstants.GAME_HEIGHT / GameConstants.GAME_WIDTH) / aspectRatio;
        }

        GameManager.readGameData();
    }

    public static readGameData(): void {

        GameManager.getGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            function (gameData: string): void {

                if (gameData) {
                    GameVars.gameData = JSON.parse(gameData);
                } else {
                    GameVars.gameData = {
                        muted: false,
                        highScore: 0,
                        boardData: []
                    };
                }

                GameManager.startGame();
            }
        );
    }

    public static setCurrentScene(scene: Phaser.Scene): void {

        GameVars.currentScene = scene;
    }

    public static onGameAssetsLoaded(): void {

        AudioManager.init();

        GameManager.enterBoardScene();
    }

    public static enterSplashScene(): void {

        GameVars.currentScene.scene.start("SplashScene");
    }

    public static enterBoardScene(): void {
        
        GameVars.currentScene.scene.start("BoardScene");
    }

    public static resetGame(): void {

        GameManager.enterBoardScene();
    }

    public static writeGameData(): void {

        GameManager.setGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            GameVars.gameData,
            function (): void {
                GameManager.log("game data successfully saved");
            }
        );
    }

    public static log(text: string, error?: Error): void {

        if (!GameConstants.VERBOSE) {
            return;
        }

        if (error) {
            console.error(text + ":", error);
        } else {
            console.log(text);
        }
    }

    private static startGame(): void {

        GameVars.currentScene.scene.start("PreloadScene");
    }
    
    private static getGameStorageData(key: string, successCb: Function): void {

        const gameDataStr = localStorage.getItem(key);
        successCb(gameDataStr);
    }

    private static setGameStorageData(key: string, value: any, successCb: Function): void {

        localStorage.setItem(key, JSON.stringify(value));
        successCb();
    }
}
