import { GameVars } from "./../../GameVars";
import { GameManager } from "./../../GameManager";
import { BoardContainer } from "./board-container/BoardContainer";
import { BoardScene } from "./BoardScene";
import { GameConstants } from "./../../GameConstants";
import { Tetromino } from "./board-container/Tetromino";
import { AudioManager } from "../../AudioManager";

export class BoardManager {

    public static init(): void {
        
        GameVars.score = 0;
        GameVars.time = 0;
        GameVars.linesRemoved = 0;
        GameVars.level = 1;
        GameVars.paused = false;
        GameVars.isHighscore = false;
        GameVars.paused = true;

        GameVars.initTime = Date.now();
        
        GameVars.board = [];

        for (let i = 0; i < GameConstants.BOARD_WIDTH; i++) {
            GameVars.board[i] = [];
            for (let j = 0; j < GameConstants.BOARD_HEIGHT; j++) {
                GameVars.board[i].push(0);
            }
        }

        GameVars.nextTetrominoShapes = [];
        GameVars.nextTetrominoShapes.push(Math.floor(Math.random() * 7));
        GameVars.nextTetrominoShapes.push(Math.floor(Math.random() * 7));
        GameVars.nextTetrominoShapes.push(Math.floor(Math.random() * 7));
        GameVars.timeoutFall = GameConstants.INIT_TIMEOUT_FALL;
    }

    public static setNextTetrominoShape(): void {

        GameVars.nextTetrominoShapes.shift();
        GameVars.nextTetrominoShapes.push(Math.floor(Math.random() * 7));
        BoardScene.currentInstance.showNextTetromino();
    }

    public static startGame(): void {

        GameVars.paused = false;
        BoardScene.currentInstance.startGame();
    }

    public static fall(): void {

        if (BoardManager.canMoveTetromino("down")) {
            BoardManager.moveTetromino("down");
        } else {
            AudioManager.playSound("locked_piecemp3");
            BoardManager.updateBoard();
            BoardManager.checkLines();
        }
    }

    public static updateBoard(): void {

        let tetromino = BoardScene.currentInstance.getTetromino();

        let stars = [];

        for (let i = 0; i < tetromino.cells.length; i++) {

            let isSpecial = false;

            for (let j = 0; j < tetromino.specialPos.length; j++) {
                if (tetromino.specialPos[j] === i) {
                    GameVars.board[tetromino.cells[i].x][tetromino.cells[i].y] = 2;
                    stars.push({x: tetromino.cells[i].x, y: tetromino.cells[i].y});
                    isSpecial = true;
                }
            }

            if (!isSpecial) {
                GameVars.board[tetromino.cells[i].x][tetromino.cells[i].y] = 1;
            }
        }

        BoardContainer.currentInstance.tetrominoToBoard();

        for (let i = 0; i < GameVars.board[0].length; i++) {
            for (let j = 0; j < GameVars.board.length; j++) {
                if (GameVars.board[j][i] === 2) {
                    if (!BoardContainer.currentInstance.isSpecialBlock(j, i)) {
                        let bool = BoardManager.checkAnimationStars(j, i);
                        if (bool) {
                            AudioManager.playSound("stars_connected");
                            BoardContainer.currentInstance.animationStar(j, i);
                        }
                    }
                }
            }
        }

        BoardManager.resetBoardStars();
    }

    public static minRow(): number {

        for (let i = 0; i < GameVars.board[0].length; i++) {
            for (let j = 0; j < GameVars.board.length; j++) {
                if (GameVars.board[j][i] !== 0) {
                    return i;
                }
            }
        }
    }

    public static checkAnimationStars(x: number, y: number): boolean {

        let bool = false;

        if (x > 0 && GameVars.board[x - 1][y] === 2) {
            GameVars.board[x][y] = 3;
            BoardManager.checkAnimationStars(x - 1, y);
            BoardContainer.currentInstance.animationStar(x - 1, y);
            bool = true;
        } 
        
        if (x < GameConstants.BOARD_WIDTH - 1 && GameVars.board[x + 1][y] === 2) {
            GameVars.board[x][y] = 3;
            BoardManager.checkAnimationStars(x + 1, y);
            BoardContainer.currentInstance.animationStar(x + 1, y);
            bool = true;
        }

        if (y > 0 && GameVars.board[x][y - 1] === 2) {
            GameVars.board[x][y] = 3;
            BoardManager.checkAnimationStars(x, y - 1);
            BoardContainer.currentInstance.animationStar(x, y - 1);
            bool = true;
        }

        if (y < GameConstants.BOARD_HEIGHT - 1 && GameVars.board[x][y + 1] === 2) {
            GameVars.board[x][y] = 3;
            BoardManager.checkAnimationStars(x, y + 1);
            BoardContainer.currentInstance.animationStar(x, y + 1);
            bool = true;
        }

        return bool;
    }

    public static canMoveTetromino(dir: string): boolean {

        let tetromino = BoardScene.currentInstance.getTetromino();

        for (let i = 0; i < tetromino.cells.length; i++) {
            const new_x = tetromino.cells[i].x + Tetromino.move_offsets[dir][0];
            const new_y = tetromino.cells[i].y + Tetromino.move_offsets[dir][1];
            if (!BoardManager.validateCoordinates(new_x, new_y)) {
                return false;
            }
        }
        return true;
    }

    public static moveTetromino(dir: string): void {

        AudioManager.playSound("row_descend");

        let tetromino = BoardScene.currentInstance.getTetromino();

        for (let i = 0; i < tetromino.cells.length; i++) {
            tetromino.cells[i].x += Tetromino.move_offsets[dir][0];
            tetromino.cells[i].y += Tetromino.move_offsets[dir][1];
        }

        tetromino.center.x += Tetromino.move_offsets[dir][0];
        tetromino.center.y += Tetromino.move_offsets[dir][1];

        tetromino.x += Tetromino.move_offsets[dir][0] * GameConstants.BOARD_CELL_SIZE;
        tetromino.y += Tetromino.move_offsets[dir][1] * GameConstants.BOARD_CELL_SIZE;

        if (dir === "down") {
            tetromino.blocksContainer.y = 2;

            GameVars.currentScene.time.addEvent({ delay: 50, callback: () => {
                tetromino.blocksContainer.y = 0;
            }, callbackScope: this});
        }

        tetromino.tetrominoShadow.updatePosition(tetromino);
    }

    public static moveTetrominoToEnd(): void {

        AudioManager.playSound("hard_drop");

        let tetromino = BoardScene.currentInstance.getTetromino();

        let offY = GameConstants.BOARD_HEIGHT;

        for (let i = 0; i < tetromino.cells.length; i++) {

            let auxOffY = 0;

            for (let j = tetromino.cells[i].y + 1; j < GameConstants.BOARD_HEIGHT; j++) {

                if (GameVars.board[tetromino.cells[i].x][j] === 0) {
                    auxOffY++;
                } else {
                    break;
                }
            }

            if (auxOffY < offY) {
                offY = auxOffY;
            }
        }

        BoardContainer.currentInstance.hardDropAnimation(offY);

        for (let i = 0; i < tetromino.cells.length; i++) {
            tetromino.cells[i].y += offY;
        }

        tetromino.center.y += offY;
        BoardScene.currentInstance.f = GameVars.timeoutFall;

        GameVars.onAnimation = true;

        tetromino.tetrominoShadow.visible = false;

        GameVars.currentScene.tweens.add({
            targets: tetromino,
            y: tetromino.y + offY * GameConstants.BOARD_CELL_SIZE,
            ease: Phaser.Math.Easing.Cubic.In,
            duration: 100,
            onComplete: () => {
                GameVars.onAnimation = false;
            },
            onCompleteScope: GameVars.currentScene
        });
    }

    public static canRotateTetromino(dir: string, shape: number): boolean {

        if (shape === 3) {
            return true;
        }

        let tetromino = BoardScene.currentInstance.getTetromino();
        for (let i = 0; i < tetromino.cells.length; i++) {
            let offset_x = tetromino.cells[i].x - tetromino.center.x;
            let offset_y = tetromino.cells[i].y - tetromino.center.y;
            offset_y = -offset_y;
            let new_offset_x = ((dir === "clockwise")) ? offset_y : -offset_y;
            let new_offset_y = ((dir === "clockwise")) ? -offset_x : offset_x;
            new_offset_y = -new_offset_y;
            let new_x = tetromino.center.x + new_offset_x;
            let new_y = tetromino.center.y + new_offset_y;
            if (!BoardManager.validateCoordinates(new_x, new_y)) {
                return false;
            }
        }
        return true;
    }

    public static rotateTetromino(dir: string, type: number): void {

        let tetromino = BoardScene.currentInstance.getTetromino();

        for (let i = 0; i < tetromino.cells.length; i++) {
            let offset_x = tetromino.cells[i].x - tetromino.center.x;
            let offset_y = tetromino.cells[i].y - tetromino.center.y;

            let new_offset_x;
            let new_offset_y;

            if (type === 3) {
                if (offset_x === -1 && offset_y === -1) {
                    new_offset_x = 0;
                    new_offset_y = -1;
                } else if (offset_x === 0 && offset_y === -1) {
                    new_offset_x = 0;
                    new_offset_y = 0;
                } else if (offset_x === 0 && offset_y === 0) {
                    new_offset_x = -1;
                    new_offset_y = 0;
                }  else if (offset_x === -1 && offset_y === 0) {
                    new_offset_x = -1;
                    new_offset_y = -1;
                }
            } else {
                offset_y = -offset_y;
                new_offset_x = (dir === "clockwise") ? offset_y : -offset_y;
                new_offset_y = (dir === "clockwise") ? -offset_x : offset_x;
                new_offset_y = -new_offset_y;
            }

            let new_x = tetromino.center.x + new_offset_x;
            let new_y = tetromino.center.y + new_offset_y;
            tetromino.cells[i].x = new_x;
            tetromino.cells[i].y = new_y;
            tetromino.blocks[i].x = (new_x - tetromino.center.x) * GameConstants.BOARD_CELL_SIZE;
            tetromino.blocks[i].y = (new_y - tetromino.center.y) * GameConstants.BOARD_CELL_SIZE;
        }

        tetromino.tetrominoShadow.updatePosition(tetromino);
    }

    public static getTetrominoShadowPositions(tetromino: Tetromino): {x: number, y: number}[] {

        let positions = [];

        let offY = GameConstants.BOARD_HEIGHT;

        for (let i = 0; i < tetromino.cells.length; i++) {

            let auxOffY = 0;

            for (let j = tetromino.cells[i].y + 1; j < GameConstants.BOARD_HEIGHT; j++) {

                if (GameVars.board[tetromino.cells[i].x][j] === 0) {
                    auxOffY++;
                } else {
                    break;
                }
            }

            if (auxOffY < offY) {
                offY = auxOffY;
            }
        }

        for (let i = 0; i < tetromino.cells.length; i++) {
            positions.push({x: tetromino.cells[i].x, y: tetromino.cells[i].y + offY});
        }

        return positions;
    }

    public static checkLines(): void {

        let lines = [];

        BoardManager.createCombosBoard();

        let line = BoardManager.canRemoveLine();

        while (line !== - 1) {
            lines.push(line);
            BoardManager.removeLine(line);
            line = BoardManager.canRemoveLine();
        }

        if (lines.length) {

            BoardManager.updateScore(lines.length);

            GameVars.onAnimation = true;

            AudioManager.playSound("line_cleared");

            let volume;

            switch (lines.length) {
                case 1:
                    volume = 0.01;
                    break;
                case 2:
                    volume = .3;
                    break;
                case 3:
                    volume = .6;
                    break;
                case 4:
                    volume = 1;
                    break;
                default:
            }

            AudioManager.playSound("reinforcement_line_clear", false, volume);

            let stars = BoardContainer.currentInstance.getStarsPositions(lines);

            BoardManager.removeLinesFromScene(lines);
            BoardScene.currentInstance.updateScore(lines.length);

            let duration = 50 + 50 * lines.length;
            let intensity = .0025 + .0025 * lines.length;

            if (lines.length === 4) {
                duration *= 2;
                intensity *= 1.5;
            }

            GameVars.currentScene.cameras.main.shake(duration, intensity);

            BoardContainer.currentInstance.animationWave(lines[0]);

            let off = stars ? 200 : 0;

            if (stars) {
                BoardManager.updateScoreCombos();
                GameVars.currentScene.time.addEvent({ delay: 1000, callback: () => {
                    BoardScene.currentInstance.updateScore(0);
                }, callbackScope: GameVars.currentScene});
            }

            GameVars.currentScene.time.addEvent({ delay: 600 + off, callback: () => {
                BoardManager.updateFromLinesRemoved(lines);
            }, callbackScope: GameVars.currentScene});

            GameVars.currentScene.time.addEvent({ delay: 800 + off, callback: () => {
                GameVars.onAnimation = false;
                BoardContainer.currentInstance.nextTetromino();
            }, callbackScope: GameVars.currentScene});
        } else {
            BoardContainer.currentInstance.nextTetromino();
        }
    }

    public static checkCombosBoard(x: number, y: number): number {

        let count = 1;
        GameVars.combosBoard[x][y] = 2;

        if (x > 0 && GameVars.combosBoard[x - 1][y] === 1) {
            count += BoardManager.checkCombosBoard(x - 1, y);
            // count++;
        } 
        
        if (x < GameConstants.BOARD_WIDTH - 1 && GameVars.combosBoard[x + 1][y] === 1) {
            count += BoardManager.checkCombosBoard(x + 1, y);
            // count++;
        }

        if (y > 0 && GameVars.combosBoard[x][y - 1] === 1) {
            count += BoardManager.checkCombosBoard(x, y - 1);
            // count++;
        }

        if (y < GameConstants.BOARD_HEIGHT - 1 && GameVars.combosBoard[x][y + 1] === 1) {
            count += BoardManager.checkCombosBoard(x, y + 1);
            // count++;
        }

        return count;

    }

    public static createCombosBoard(): void {

        GameVars.combosBoard = [];

        for (let i = 0; i < GameConstants.BOARD_WIDTH; i++) {
            GameVars.combosBoard[i] = [];
            for (let j = 0; j < GameConstants.BOARD_HEIGHT; j++) {
                GameVars.combosBoard[i].push(0);
            }
        }
    }

    public static resetCombosBoard(): void {

        for (let i = 0; i < GameConstants.BOARD_WIDTH; i++) {
            for (let j = 0; j < GameConstants.BOARD_HEIGHT; j++) {
                if (GameVars.combosBoard[i][j] === 2) {
                    GameVars.combosBoard[i][j] = 1;
                }
            }
        }
    }

    public static updateScore(length: number): void {

        switch (length) {
            case 1:
                GameVars.score += 40 * GameVars.level;
                break;
            case 2:
                GameVars.score += 100 * GameVars.level;
                break;
            case 3:
                GameVars.score += 300 * GameVars.level;
                break;
            case 4:
                GameVars.score += 1200 * GameVars.level;
                break;
            default:
        }
    }

    public static updateScoreCombos(): void {

        BoardManager.resetCombosBoard();

        let combos = [];

        for (let i = 0; i < GameConstants.BOARD_WIDTH; i++) {
            for (let j = 0; j < GameConstants.BOARD_HEIGHT; j++) {
                if (GameVars.combosBoard[i][j] === 1) {
                    let combo = BoardManager.checkCombosBoard(i, j);
                    if (combo > 1) {
                        combos.push({count: combo, row: j});
                    }
                }
            }
        }


        let totalPoints = 0;

        for (let i = 0; i < combos.length; i++) {
            GameVars.score += combos[i].count * combos[i].count * 25 * GameVars.level;
            totalPoints += combos[i].count * combos[i].count * 25 * GameVars.level;
        }

        if (combos.length) {
            BoardContainer.currentInstance.showCombosPoints(combos, totalPoints);
        }
        
    }

    public static removeLinesFromScene(lines: number[]): void {

        BoardContainer.currentInstance.removeLines(lines); 
    }

    public static canRemoveLine(): number {

        for (let i = GameVars.board[0].length - 1; i >= 0 ; i--) {
            let isLine = true;
            for (let j = 0; j < GameVars.board.length; j++) {
                if (GameVars.board[j][i] === 0) {
                    isLine = false;
                }
            }

            if (isLine) {
                return i;
            }
        }

        return -1;
    }

    public static removeLine(line: number): void {

        GameVars.linesRemoved++;

        if (GameVars.linesRemoved % 10 === 0) {
            GameVars.level++;
            BoardScene.currentInstance.updateLevel();
            GameVars.timeoutFall = Math.max(5, GameVars.timeoutFall - 5);
        }

        for (let i = 0; i < GameVars.board.length; i++) {

            if (GameVars.board[i][line] === 2) {
                GameVars.combosBoard[i][line] = 1;
            }
            GameVars.board[i][line] = 0;
        }
    }

    public static updateFromLinesRemoved(lines: number[]): void {

        for (let i = GameVars.board[0].length - 1; i >= 0; i--) {

            let num = 0;

            for (let j = 0; j < lines.length; j++) {

                if (lines[j] > i) {
                    num++;
                }
            }

            if (num > 0) {
                for (let j = 0; j < GameVars.board.length; j++) {
                    GameVars.board[j][i + num] = GameVars.board[j][i];
                    GameVars.board[j][i] = 0;
                }
    
                BoardContainer.currentInstance.updateBlocks(i, i + num);
            }
        }

        GameVars.currentScene.time.addEvent({ delay: 100, callback: () => {
            BoardManager.checkNewsCombos();
        }, callbackScope: GameVars.currentScene});

    }

    public static checkNewsCombos(): void {

        for (let i = 0; i < GameVars.board[0].length; i++) {
            for (let j = 0; j < GameVars.board.length; j++) {
                if (GameVars.board[j][i] === 2) {
                    if (!BoardContainer.currentInstance.isSpecialBlock(j, i)) {
                        let bool = BoardManager.checkAnimationStars(j, i);
                        if (bool) {
                            AudioManager.playSound("stars_connected");
                            BoardContainer.currentInstance.animationStar(j, i);
                        }
                    } else {
                        let bool = BoardManager.checkAnimationStars(j, i);
                        if (!bool) {
                            BoardContainer.currentInstance.removeSpecial(j, i);
                        }
                    }
                    BoardManager.resetBoardStars();
                }
            }
        }
    }

    public static resetBoardStars(): void {

        for (let i = 0; i < GameVars.board[0].length; i++) {
            for (let j = 0; j < GameVars.board.length; j++) {
                if (GameVars.board[j][i] === 3) {
                    GameVars.board[j][i] = 2;
                }
            }
        }
    }

    public static isGameOver(): boolean {

        let tetromino = BoardScene.currentInstance.getTetromino();
        for (let i = 0; i < tetromino.cells.length; i++) {
            if (!BoardManager.validateCoordinates(tetromino.cells[i].x, tetromino.cells[i].y)) {
                return true;
            }
        }
        return false;
    }

    public static gameOver(): void {

        AudioManager.setLoopVolume(0);

        GameVars.paused = true;

        if (GameVars.gameData.highScore !== 0 && GameVars.score > GameVars.gameData.highScore) {
            GameVars.isHighscore = true;
        }

        if (GameVars.score > GameVars.gameData.highScore) {
            GameVars.gameData.highScore = GameVars.score;            
            GameManager.writeGameData();
        }

        BoardContainer.currentInstance.tetrominoToBoard();

        BoardContainer.currentInstance.gameOverAnimation();

        GameVars.currentScene.time.addEvent({ delay: 1500, callback: () => {
            BoardScene.currentInstance.showGameOver();
        }, callbackScope: this});

        GameVars.simplio.SetScore(GameVars.simplioUserId, GameVars.score).then(() => {
            console.warn("Score updated");
        });
    }

    public static validateCoordinates(new_x: number, new_y: number): boolean {

        if (new_x < 0 || new_x > GameConstants.BOARD_WIDTH - 1) {
            // console.log("Out of X bounds");
            return false;
        }
        if (new_y < 0 || new_y > GameConstants.BOARD_HEIGHT - 1) {
            // console.log("Out of Y bounds");
            return false;
        }
        if (GameVars.board[new_x][new_y] !== 0) {
            // console.log("Cell is occupied");
            return false;
        }
        return true;
    }

    public static pauseGame(): void {

        if (GameVars.paused) {
            return;
        }

        GameVars.paused = true;
        BoardScene.currentInstance.showPauseMenu();
    }

    public static unPauseGame(): void {

        GameVars.paused = false;
        BoardScene.currentInstance.hidePauseMenu();
    }
}
