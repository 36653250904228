export class GameVars {

    public static gameData: GameData;
    public static scaleY: number;
    public static isDesktop: boolean;
    public static aspectRatio: number;
    public static currentScene: Phaser.Scene;
    public static intitialised: boolean;
    public static paused: boolean;
    public static onAnimation: boolean;
    public static score: number;
    public static time: number;
    public static isHighscore: boolean;
    public static board: number[][];
    public static nextTetrominoShapes: number[];
    public static timeoutFall: number;
    public static linesRemoved: number;
    public static level: number;
    public static combosBoard: number[][];
    public static initTime: number;

    public static simplioApiUrl: string;
    public static simplioUserId: string;
    public static simplioGameId: string;
    public static simplio: any;

    public static areValidDomains(domains: string[]): boolean {

        if (domains.length === 0) {
            return true;
        }

        const hostname = window.location.hostname;
        const referrer = document.referrer;

        let isValid = false;

        for (let i = 0; i < domains.length; i++) {
            if (hostname.indexOf(domains[i]) !== -1) {
                isValid = true;
                break;
            }
        }

        if (referrer) {

            isValid = false;
            
            for (let i = 0; i < domains.length; i++) {

                if (referrer.indexOf(domains[i]) !== -1) {
                    isValid = true;
                    break;
                }
            }
        }

        return isValid;
    }
  
    public static formatNumber(value: number): string {

        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}
