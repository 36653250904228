export class Block extends Phaser.GameObjects.Container {

    public outline: Phaser.GameObjects.Sprite;
    public backlight: Phaser.GameObjects.Sprite;
    public col: number;
    public row: number;
    public isSpecial: boolean;

    private currentFrame: number;
    private currentAnimation: string;
    private starImg: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene, col: number, row: number, x: number, y: number, initFrame: number, anim?: boolean) {
        
        super(scene);

        this.setPosition(x, y);

        this.col = col; 
        this.row = row;

        this.isSpecial = false;

        this.backlight = this.scene.add.sprite(0, 0, "texture_atlas_1");
        this.backlight.blendMode = Phaser.BlendModes.SCREEN;
        this.add(this.backlight);

        this.backlight.anims.play("block_backlight", false, initFrame);
        this.currentAnimation = "block_backlight";
    }

    public animationWave(initLine: number): void {

        if (this.isSpecial) {
            return;
        }

        this.currentFrame = this.backlight.anims.currentFrame.index;

        let count = Math.abs(this.row - initLine);

        this.scene.time.addEvent({ delay: ((count * 3) * 1000) / 60, callback: () => {
            this.backlight.anims.play("block_backlight_simple", false);
            this.backlight.on("animationcomplete", () => {
                if (this.backlight.anims.currentAnim.key === "block_backlight_simple") {
                    this.backlight.anims.play(this.currentAnimation, false, this.currentFrame);
                }
            }, this);
        }, callbackScope: this});
    }

    public gameOverAnimation(): void {

        this.scene.time.addEvent({ delay: 50 * this.row, callback: () => {
            this.removeSpecial();
            this.backlight.anims.stop();
            this.backlight.setFrame("block_backlight_0002");
        }, callbackScope: this});
    }

    public starMode() {

        this.isSpecial = true;

        if (this.starImg) {
            this.starImg.blendMode = Phaser.BlendModes.NORMAL;
            this.starImg.setFrame("star_row_cleared");
        }
    }

    public setSpecial(): void {

        this.starImg = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "star");
        this.starImg.blendMode = Phaser.BlendModes.OVERLAY;
        this.add(this.starImg);
    }

    public removeSpecial(): void {

        this.isSpecial = false;

        if (this.starImg) {
            this.starImg.blendMode = Phaser.BlendModes.OVERLAY;
            this.starImg.setFrame("star");
        }
    }
}
