import { Button } from "../../utils/Utils";
import { GameVars } from "../../GameVars";
import { BoardManager } from "./BoardManager";
import { AudioManager } from "../../AudioManager";

export class GUI extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        const pauseButton = new Button(this.scene, 47, 47 * GameVars.scaleY, "texture_atlas_1", "btn_menu", "btn_menu_hover");
        pauseButton.onDown(this.onClickPauseMenu, this);
        pauseButton.setScale(.6, GameVars.scaleY * .6);
        this.add(pauseButton);
    }

    private onClickPauseMenu(): void {
        
        BoardManager.pauseGame();

        AudioManager.playSound("btn_pressed");
    }
}
