import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { Button } from "../../utils/Utils";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";

export class GameOverLayer extends Phaser.GameObjects.Container {

    private keySpace: Phaser.Input.Keyboard.Key;

    private midContainer: Phaser.GameObjects.Container;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        AudioManager.playSound("game_over_menu_appears");

        this.scene.time.addEvent({ delay: 500, callback: () => {
            AudioManager.playSound("loop_gameover", true, .5);
        }, callbackScope: this});
        

        this.x = GameConstants.GAME_WIDTH / 2;
        this.y = GameConstants.GAME_HEIGHT / 2;
        this.scaleY = GameVars.scaleY;

        const bck = new Phaser.GameObjects.Graphics(this.scene);
        bck.fillStyle(0x051429, .8);
        bck.fillRect(-GameConstants.GAME_WIDTH / 2, -(GameConstants.GAME_HEIGHT / GameVars.scaleY) / 2, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT / GameVars.scaleY);
        bck.setInteractive(new Phaser.Geom.Rectangle(-GameConstants.GAME_WIDTH / 2, -(GameConstants.GAME_HEIGHT / GameVars.scaleY) / 2, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT / GameVars.scaleY), Phaser.Geom.Rectangle.Contains);
        bck.on("pointerdown", () => { 
            // do nothing
        });
        this.add(bck);

        this.midContainer = new Phaser.GameObjects.Container(this.scene);
        this.add(this.midContainer);

        const imgBck = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "popup_layout");
        this.midContainer.add(imgBck);

        const imgHighScore = new Phaser.GameObjects.Image(this.scene, 0, -155, "texture_atlas_1", "layout_score");
        this.midContainer.add(imgHighScore);

        if (GameVars.isHighscore) {

            let icon = new Phaser.GameObjects.Image(this.scene, -130, -155, "texture_atlas_1", "icon_new_best_score");
            this.midContainer.add(icon);

            icon = new Phaser.GameObjects.Image(this.scene, 130, -155, "texture_atlas_1", "icon_new_best_score");
            this.midContainer.add(icon);

            let glare = new Phaser.GameObjects.Image(this.scene, -130, -155, "texture_atlas_1", "glare");
            glare.alpha = .5;
            glare.blendMode = Phaser.BlendModes.OVERLAY;
            this.midContainer.add(glare);

            this.scene.tweens.add({
                targets: glare,
                angle: 360,
                ease: Phaser.Math.Easing.Linear,
                duration: 3000,
                repeat: -1
            });

            glare = new Phaser.GameObjects.Image(this.scene, 130, -155, "texture_atlas_1", "glare");
            glare.alpha = .5;
            glare.blendMode = Phaser.BlendModes.OVERLAY;
            this.midContainer.add(glare);

            this.scene.tweens.add({
                targets: glare,
                angle: 360,
                ease: Phaser.Math.Easing.Linear,
                duration: 3000,
                repeat: -1
            });

            const imgTitle = new Phaser.GameObjects.Image(this.scene, 0, -180, "texture_atlas_1", "text_best_score");
            this.midContainer.add(imgTitle);
        } else {
            const imgTitle = new Phaser.GameObjects.Image(this.scene, 0, -180, "texture_atlas_1", "text_score");
            this.midContainer.add(imgTitle);
        }

        let scoreLabel = new Phaser.GameObjects.BitmapText(this.scene, -4, -140, "teko", GameVars.formatNumber(GameVars.score), 55);
        scoreLabel.setOrigin(.5);
        this.midContainer.add(scoreLabel);

        const imgTitle = new Phaser.GameObjects.Image(this.scene, 0, -287, "texture_atlas_1", "txt_game_over");
        this.midContainer.add(imgTitle);

        const imgScore = new Phaser.GameObjects.Image(this.scene, 0, -40, "texture_atlas_1", "stats_score");
        this.midContainer.add(imgScore);

        let text = new Phaser.GameObjects.Text(this.scene, -40, -40, "BEST", {fontFamily: "Teko", fontSize: "30px", color: "#5bff00"});
        text.setShadow(0, 0, " #5bff00", 10);
        text.setOrigin(.5);
        this.midContainer.add(text);

        text = new Phaser.GameObjects.Text(this.scene, 87, -40, GameVars.formatNumber(GameVars.gameData.highScore), {fontFamily: "Teko", fontSize: "30px", color: "#5bff00"});
        text.setShadow(0, 0, " #5bff00", 10);
        text.setOrigin(.5);
        this.midContainer.add(text);

        const imgTime = new Phaser.GameObjects.Image(this.scene, 0, 20, "texture_atlas_1", "stats_time");
        this.midContainer.add(imgTime);

        text = new Phaser.GameObjects.Text(this.scene, -40, 20, "TIME", {fontFamily: "Teko", fontSize: "30px", color: "#5bff00"});
        text.setShadow(0, 0, " #5bff00", 10);
        text.setOrigin(.5);
        this.midContainer.add(text);

        let time = Math.round((Date.now() - GameVars.initTime) / 1000);

        text = new Phaser.GameObjects.Text(this.scene, 87, 20, Math.floor(time / 60) + "' " + time % 60 + "\"", {fontFamily: "Teko", fontSize: "30px", color: "#5bff00"});
        text.setShadow(0, 0, " #5bff00", 10);
        text.setOrigin(.5);
        this.midContainer.add(text);

        if (GameVars.isHighscore) {

            let light = new Phaser.GameObjects.Image(this.scene, 0, -155, "texture_atlas_1", "yellow_lights_gameover");
            light.setOrigin(.5)
            this.midContainer.add(light);

            this.scene.tweens.add({
                targets: light,
                alpha: .75,
                ease: Phaser.Math.Easing.Linear,
                duration: 50,
                repeat: -1,
                yoyo: true
            });
        }

        this.scene.anims.create({ key: "robot_idle", frames: this.scene.anims.generateFrameNames( "texture_atlas_2", { prefix: "idle_", start: 1, end:35, zeroPad: 2, suffix: ""}), frameRate: 18, repeat: -1});
        this.scene.anims.create({ key: "robot_jump", frames: this.scene.anims.generateFrameNames( "texture_atlas_2", { prefix: "jump_", start: 1, end:18, zeroPad: 2, suffix: ""}), frameRate: 18, repeat: 0});
        
        let robot = this.scene.add.sprite(-10, 125, "texture_atlas_2");
        robot.blendMode = Phaser.BlendModes.SCREEN;
        this.add(robot);

        if (GameVars.isHighscore) {

            robot.anims.play("robot_jump");

            robot.on("animationcomplete", () => {
                if (robot.anims.currentAnim.key === "robot_jump") {
                    robot.setFrame("jump_01");
                    this.scene.time.addEvent({ delay: 500, callback: () => {
                        robot.anims.play("robot_jump");
                    }, callbackScope: this});
                }
            }, this);
        } else {
            robot.anims.play("robot_idle");
        }

        const restartButton = new Button(this.scene, 0, 290, "texture_atlas_1", "btn_restart", "btn_restart_hover");
        restartButton.onDown(() => {
            GameManager.resetGame();
            AudioManager.playSound("btn_pressed");
        }, this);
        this.midContainer.add(restartButton);

        let glare = new Phaser.GameObjects.Image(this.scene, 0, 290, "texture_atlas_1", "glare");
        glare.blendMode = Phaser.BlendModes.OVERLAY;
        this.midContainer.add(glare);

        this.scene.tweens.add({
            targets: glare,
            angle: 360,
            ease: Phaser.Math.Easing.Linear,
            duration: 5000,
            repeat: -1
        });

        this.alpha = 0;
        this.midContainer.setScale(.8, .8)
        this.visible = true;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });

        this.scene.tweens.add({
            targets: this.midContainer,
            scaleY: 1,
            scaleX: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 500
        });

        this.keySpace = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);

        this.scene.sys.updateList.add(this);
    }

    public preUpdate(): void {

        if (this.keySpace.isDown) {
            GameManager.resetGame();
        }
    }
}