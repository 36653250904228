import { BoardManager } from "./../BoardManager";
import { Tetromino } from "./Tetromino";
import { GameConstants } from "../../../GameConstants";

export class TetrominoShadow extends Phaser.GameObjects.Container {

    private blocks: Phaser.GameObjects.Image[];
    private stars: Phaser.GameObjects.Image[];

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        this.blocks = [];

        for (let i = 0; i < 4; i++) {
            let block = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "ghost_piece_block");
            this.add(block);
            this.blocks.push(block);
        }

        this.scene.tweens.add({
            targets: this,
            alpha: .5,
            ease: Phaser.Math.Easing.Linear,
            duration: 1000,
            yoyo: true,
            repeat: -1
        });

        this.stars = [];

        let star = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "star");
        star.blendMode = Phaser.BlendModes.OVERLAY;
        star.visible = false;
        this.add(star);
        this.stars.push(star);

        star = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "star");
        star.blendMode = Phaser.BlendModes.OVERLAY;
        star.visible = false;
        this.add(star);
        this.stars.push(star);
    }

    public updatePosition(tetromino: Tetromino): void {

        this.stars[0].visible = false;
        this.stars[1].visible = false;

        let positions = BoardManager.getTetrominoShadowPositions(tetromino);

        let currentStar = 0;

        for (let i = 0; i < this.blocks.length; i++) {
            this.blocks[i].x = (positions[i].x - tetromino.center.x) * GameConstants.BOARD_CELL_SIZE;
            this.blocks[i].y = (positions[i].y - tetromino.center.y) * GameConstants.BOARD_CELL_SIZE;

            for (let j = 0; j < tetromino.specialPos.length; j++) {
                if (tetromino.specialPos[j] === i) {
                    this.stars[currentStar].visible = true;
                    this.stars[currentStar].x = (positions[i].x - tetromino.center.x) * GameConstants.BOARD_CELL_SIZE;
                    this.stars[currentStar].y = (positions[i].y - tetromino.center.y) * GameConstants.BOARD_CELL_SIZE;
                    currentStar++;
                }
            }
        }
    }
}
