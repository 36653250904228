import { FramesContainer } from "./FramesContainer";
import { GameVars } from "./../../../GameVars";
import { BoardManager } from "./../BoardManager";
import { GameConstants } from "./../../../GameConstants";
import { Tetromino } from "./Tetromino";
import { Block } from "./Block";
import { BoardScene } from "../BoardScene";
import { AudioManager } from "../../../AudioManager";

export class BoardContainer extends Phaser.GameObjects.Container {

    public static currentInstance: BoardContainer;

    private tetromino: Tetromino;
    private blocks: Block[];
    private blocksContainer: Phaser.GameObjects.Container;
    private animationsContainer: Phaser.GameObjects.Container;
    private framesContainer: FramesContainer;
    private highlightColumns: Phaser.GameObjects.Image[];

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        BoardContainer.currentInstance = this;

        this.x = GameConstants.GAME_WIDTH / 2;
        this.y = GameConstants.GAME_HEIGHT / 2 + 32;
        this.scaleY = GameVars.scaleY;

        this.initAnimations();

        // BACK GRID

        let extraLines = Math.round((1 - GameVars.scaleY) * 300);

        let bck = new Phaser.GameObjects.Graphics(this.scene);
        bck.fillStyle(0x012148);
        bck.fillRect(-260, -(916 + extraLines * 2) / 2, 520, (916 + extraLines * 2) / 2);
        this.add(bck);

        bck = new Phaser.GameObjects.Graphics(this.scene);
        bck.fillStyle(0x01152F);
        bck.fillRect(-260, 0, 520, (916 + extraLines * 2) / 2);
        this.add(bck);

        let grid = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "grid");
        grid.x = - grid.width / 2;
        grid.originX = 1;
        this.add(grid);

        if (GameVars.scaleY > 1) {
            grid.setScale(.95);
            grid.x = - (grid.width / 2) * .95;
        }

        grid = new Phaser.GameObjects.Image(this.scene, grid.width / 2 - 1, 0, "texture_atlas_1", "grid");
        grid.originX = 1;
        grid.scaleX = -1;
        this.add(grid);

        if (GameVars.scaleY > 1) {
            grid.setScale(-.95, .95);
            grid.x = (grid.width / 2) * .95 - 1;
        }

        // HIGHLIGHT COLUMNS

        this.animationsContainer = new Phaser.GameObjects.Container(this.scene);
        this.add(this.animationsContainer);

        this.highlightColumns = [];

        for (let i = 0; i < GameConstants.BOARD_WIDTH; i++) {

            const initX = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_WIDTH) / 2;

            let highlightColumn = new Phaser.GameObjects.Image(this.scene, initX + GameConstants.BOARD_CELL_SIZE * (i + .5), 0, "texture_atlas_1", "column_highlight");
            highlightColumn.visible = false;
            this.animationsContainer.add(highlightColumn);
            this.highlightColumns.push(highlightColumn);
        }

        // BLOCKS

        this.blocks = [];

        this.blocksContainer = new Phaser.GameObjects.Container(this.scene);
        this.add(this.blocksContainer);

        if (GameVars.scaleY > 1) {
            this.blocksContainer.setScale(.95);
        }

        this.tetromino = new Tetromino(this.scene, GameConstants.BOARD_WIDTH / 2, 1, Math.random() < GameConstants.STAR_PERCENTAGE);
        this.tetromino.visible = false;
        this.blocksContainer.add(this.tetromino);

        // TOP GRID

        this.framesContainer = new FramesContainer(this.scene);
        this.add(this.framesContainer);

        BoardManager.setNextTetrominoShape();

        if (!GameVars.isDesktop) {
            this.setMobileInputs();
        }
    }

    public update(): void {

        this.tetromino.update();

        this.hideHighlights();

        if (!GameVars.onAnimation) {
            let maxY = 0;
            let minY = GameConstants.BOARD_HEIGHT;

            for (let i = 0; i < this.tetromino.cells.length; i++) {

                if (this.tetromino.cells[i].y < minY) {
                    minY = this.tetromino.cells[i].y;
                }

                if (this.tetromino.cells[i].y > maxY) {
                    maxY = this.tetromino.cells[i].y;
                }
            }

            for (let i = 0; i < this.tetromino.cells.length; i++) {

                const initY = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_HEIGHT) / 2;
                this.highlightColumns[this.tetromino.cells[i].x].visible = true;
                this.highlightColumns[this.tetromino.cells[i].x].y = initY + (minY + (maxY - minY + 1) / 2) * GameConstants.BOARD_CELL_SIZE;
                this.highlightColumns[this.tetromino.cells[i].x].scaleY = 1;
                this.highlightColumns[this.tetromino.cells[i].x].scaleY = (GameConstants.BOARD_CELL_SIZE * (maxY - minY + 1 + 4)) / this.highlightColumns[this.tetromino.cells[i].x].height;
            }
        }
    }

    public isSpecialBlock(x: number, y: number): boolean {

        for (let i = 0; i < this.blocks.length; i++) {
            if (this.blocks[i].col === x && this.blocks[i].row === y && this.blocks[i].isSpecial) {
                return true;
            }
        }

        return false;
    }

    public removeSpecial(x: number, y: number): void {

        for (let i = 0; i < this.blocks.length; i++) {
            if (this.blocks[i].col === x && this.blocks[i].row === y) {
                this.blocks[i].removeSpecial();
                return;
            }
        }

    }

    public startGame(): void {

        this.tetromino.visible = true;
        this.tetromino.alpha = 0;

        this.scene.tweens.add({
            targets: this.tetromino,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.In,
            duration: 100,
        });
    }

    public animationWave(initLine: number): void {

        for (let i = 0; i < this.blocks.length; i++) {
            this.blocks[i].animationWave(initLine);
        }
    }

    public tetrominoToBoard(): void {

        BoardContainer.currentInstance.hideHighlights();

        for (let i = 0; i < this.tetromino.cells.length; i++) {

            const initX = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_WIDTH) / 2;
            const initY = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_HEIGHT) / 2;

            let x = initX + GameConstants.BOARD_CELL_SIZE * (this.tetromino.cells[i].x + .5);
            let y = initY + GameConstants.BOARD_CELL_SIZE * (this.tetromino.cells[i].y + .5);

            let block = new Block(this.scene, this.tetromino.cells[i].x, this.tetromino.cells[i].y, x, y, this.tetromino.blocks[0].backlight.anims.currentFrame.index, true);
            this.blocksContainer.add(block);
            this.blocks.push(block);

            for (let j = 0; j < this.tetromino.specialPos.length; j++) {
                if (this.tetromino.specialPos[j] === i) {
                    block.setSpecial();
                }
            }
        }

        this.tetromino.destroy();
    }

    public animationStar(x: number, y: number): void {

        for (let i = 0; i < this.blocks.length; i++) {
            if (this.blocks[i].col === x && this.blocks[i].row === y && !this.blocks[i].isSpecial) {
                let highlight = new Phaser.GameObjects.Image(this.scene, this.blocks[i].x, this.blocks[i].y, "texture_atlas_1", "highlight_paired_star");
                highlight.blendMode = Phaser.BlendModes.SCREEN;
                this.add(highlight);

                this.blocks[i].starMode();

                this.scene.tweens.add({
                    targets: highlight,
                    alpha: 0,
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 250,
                    delay: 250,
                    onComplete: () => {
                        highlight.destroy();
                    },
                    onCompleteScope: this
                });
                return;
            }
        }
    }

    public nextTetromino(): void {

        let initPos = 1;

        if (BoardManager.minRow() < 6) {
            initPos = 0;
        }

        this.tetromino = new Tetromino(this.scene, GameConstants.BOARD_WIDTH / 2, initPos, Math.random() < GameConstants.STAR_PERCENTAGE);
        this.blocksContainer.add(this.tetromino);

        BoardManager.setNextTetrominoShape();

        if (BoardManager.isGameOver()) {
            BoardManager.gameOver();
        }
    }

    public gameOverAnimation(): void {

        for (let i = 0; i < this.blocks.length; i++) {
            this.blocks[i].gameOverAnimation();
        }

        AudioManager.playSound("game_over");
    }

    public getStarsPositions(lines: number[]): number {

        let stars = 0;

        let extraLines = Math.round((1 - GameVars.scaleY) * 300);

        for (let i = 0; i < lines.length; i++) {
            for (let j = 0; j < this.blocks.length; j++) {
                if (this.blocks[j].row === lines[i] && this.blocks[j].isSpecial && BoardManager.checkCombosBoard(this.blocks[j].col, this.blocks[j].row) > 1) {
                    stars++;
                    let star = new Phaser.GameObjects.Image(this.scene, this.blocks[j].x, this.blocks[j].y, "texture_atlas_1", "star_row_cleared");
                    this.add(star);

                    this.scene.tweens.add({
                        targets: star,
                        scaleX: 0,
                        ease: Phaser.Math.Easing.Cubic.In,
                        duration: 150,
                        delay: 300
                    });

                    this.scene.tweens.add({
                        targets: star,
                        scaleY: 1.2,
                        ease: Phaser.Math.Easing.Linear,
                        duration: 150,
                        delay: 300,
                        yoyo: true
                    });

                    this.scene.tweens.add({
                        targets: star,
                        scaleX: 1,
                        ease: Phaser.Math.Easing.Cubic.Out,
                        duration: 150,
                        delay: 450
                    });

                    this.scene.tweens.add({
                        targets: star,
                        x: 0,
                        y: -540 - extraLines,
                        ease: Phaser.Math.Easing.Cubic.Out,
                        duration: 500,
                        delay: 700,
                        onComplete: () => {
                            star.destroy();
                        },
                        onCompleteScope: this,
                        onStart: () => {
                            AudioManager.playSound("stars_combo");
                        },
                        onStartScope: this
                    });

                    this.scene.tweens.add({
                        targets: star,
                        alpha: 0,
                        ease: Phaser.Math.Easing.Cubic.Out,
                        duration: 200,
                        delay: 900
                    });

                    BoardManager.resetCombosBoard();
                }
            }
        }

        return stars;
    }

    public showCombosPoints(combos: {count: number, row: number}[], points: number): void {

        let totalRows = 0;

        for (let i = 0; i < combos.length; i++) {
            totalRows += combos[i].row;
        }

        let row = totalRows / combos.length;

        const initX = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_WIDTH) / 2;
        const initY = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_HEIGHT) / 2;

        this.scene.time.addEvent({ delay: 200, callback: () => {
            let text = new Phaser.GameObjects.BitmapText(this.scene, initX + (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_WIDTH) / 2, initY + (row + .5) * GameConstants.BOARD_CELL_SIZE + 5, "teko-yellow", "+" + points, 50);
            text.setOrigin(.5);
            this.add(text);

            this.scene.tweens.add({
                targets: text,
                y: text.y - 20,
                alpha: 0,
                ease: Phaser.Math.Easing.Cubic.In,
                duration: 400,
                delay: 400,
                onComplete: () => {
                    text.destroy();
                },
                onCompleteScope: this
            });

            
        }, callbackScope: this});
    }

    public removeLines(lines: number[]): void {

        for (let i = 0; i < lines.length; i++) {

            const initY = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_HEIGHT) / 2;

            let lineBright = new Phaser.GameObjects.Image(this.scene, 0, initY + GameConstants.BOARD_CELL_SIZE * (lines[i] + .5), "texture_atlas_1", "row_cleared");
            lineBright.blendMode = Phaser.BlendModes.SCREEN;           
            this.add(lineBright);

            this.scene.tweens.add({
                targets: lineBright,
                alpha: 0,
                ease: Phaser.Math.Easing.Cubic.In,
                duration: 400,
                onComplete: () => {
                    lineBright.destroy();
                },
                onCompleteScope: this
            });

            for (let j = 0; j < this.blocks.length; j++) {
                if (this.blocks[j].row === lines[i]) {
                    this.blocks[j].destroy();
                    this.blocks.splice(j, 1);
                    j--;
                }
            }
        }
    }

    public updateBlocks(y: number, newY: number): void {

        for (let i = 0; i < this.blocks.length; i++) {
            let value = this.blocks[i].row;

            if (value === y) {
                this.blocks[i].row = newY;
                this.scene.tweens.add({
                    targets: this.blocks[i],
                    y: this.blocks[i].y + GameConstants.BOARD_CELL_SIZE * (newY - y),
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 100
                });
            }
        }
    }

    public hideHighlights(): void {

        for (let i = 0; i < this.highlightColumns.length; i++) {
            this.highlightColumns[i].visible = false;
        }
    }

    public hardDropAnimation(offY: number): void {

        let arrayX = [];

        for (let i = 0; i < this.tetromino.cells.length; i++) {
            if (arrayX.indexOf(this.tetromino.cells[i].x) === -1) {
                arrayX.push(this.tetromino.cells[i].x);
            }
        }

        let maxY = 0;
        let minY = GameConstants.BOARD_HEIGHT;

        for (let i = 0; i < this.tetromino.cells.length; i++) {

            if (this.tetromino.cells[i].y < minY) {
                minY = this.tetromino.cells[i].y;
            }

            if (this.tetromino.cells[i].y > maxY) {
                maxY = this.tetromino.cells[i].y;
            }
        }

        const initX = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_WIDTH) / 2;
        const initY = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_HEIGHT) / 2;

        for (let i = 0; i < arrayX.length; i++) {
            
            let anim = new Phaser.GameObjects.Image(this.scene, initX + GameConstants.BOARD_CELL_SIZE * (arrayX[i] + .5), initY + GameConstants.BOARD_CELL_SIZE * (maxY + offY + 1), "texture_atlas_1", "hard_drop_trail");
            anim.setOrigin(.5, 1);
            this.animationsContainer.add(anim);

            anim.scaleY = ((maxY + offY + 1) * GameConstants.BOARD_CELL_SIZE) / anim.height;

            this.scene.tweens.add({
                targets: anim,
                scaleY: 1,
                ease: Phaser.Math.Easing.Cubic.In,
                duration: 300,
                onComplete: () => {
                    anim.destroy();
                },
                onCompleteScope: this
            });
        }
    }

    public getTetromino(): Tetromino {

        return this.tetromino;
    }

    public updateLevel(): void {

        this.framesContainer.updateLevel();
    }

    private initAnimations(): void {

        this.scene.anims.create({ key: "block_backlight", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "block_backlight_", start: 1, end: 49, zeroPad: 4, suffix: ""}), frameRate: 15, repeat: -1});
        this.scene.anims.create({ key: "block_backlight_simple", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "block_backlight_", start: 1, end: 49, zeroPad: 4, suffix: ""}), frameRate: 40});
        this.scene.anims.create({ key: "block_backlight_star", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "block_backlight_star_", start: 1, end: 4, zeroPad: 4, suffix: ""}), frameRate: 5, repeat: -1});

        this.scene.anims.create({ key: "star_cartridge_left", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "star_cartridge_left_", start: 1, end: 15, zeroPad: 2, suffix: ""}), frameRate: 15, repeat: -1});
        this.scene.anims.create({ key: "star_cartridge_middle", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "star_cartridge_middle_", start: 1, end: 15, zeroPad: 2, suffix: ""}), frameRate: 15, repeat: -1});
        this.scene.anims.create({ key: "star_cartridge_right", frames: this.scene.anims.generateFrameNames( "texture_atlas_1", { prefix: "star_cartridge_right_", start: 1, end: 15, zeroPad: 2, suffix: ""}), frameRate: 15, repeat: -1});
    }

    private setMobileInputs(): void {

        Tetromino.mobileDown = false;

        this.scene.input.on("pointerup", function(pointer: Phaser.Input.Pointer) {

            Tetromino.mobileDown = false;

            if (GameVars.paused || GameVars.onAnimation) {
                return;
            }

            let time = this.scene.input.activePointer.upTime - this.scene.input.activePointer.downTime;

            let tetromino = BoardScene.currentInstance.getTetromino();

            if (Math.abs(pointer.downX - pointer.upX) < 10 && Math.abs(pointer.downY - pointer.upY) < 10 && BoardManager.canRotateTetromino("clockwise", this.tetromino.shape)) {
                BoardManager.rotateTetromino("clockwise", this.tetromino.shape);
            } else if (time < 200 && pointer.downY - pointer.upY < -100 && Math.abs(pointer.downX - pointer.upX) < GameConstants.BOARD_CELL_SIZE) {
                BoardManager.moveTetrominoToEnd();
            }

        }, this);

        this.scene.input.on("pointerdown", function(pointer: Phaser.Input.Pointer) {

            if (GameVars.paused || GameVars.onAnimation) {
                return;
            }

            Tetromino.mobileDown = true;

        }, this);
    }
}
