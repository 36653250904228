import { GameVars } from "./../../GameVars";
import { GameConstants } from "../../GameConstants";

export class BackgroundContainer extends Phaser.GameObjects.Container {

    private effectLeft: Phaser.GameObjects.Sprite;
    private effectRight: Phaser.GameObjects.Sprite;

    constructor(scene: Phaser.Scene) {
        
        super(scene);

        let bg = this.scene.add.image(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT / 2, "bg");
        bg.setOrigin(1, .5);
        bg.setScale(2, 2 * GameVars.scaleY);

        bg = this.scene.add.image(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT / 2, "bg");
        bg.setOrigin(1, .5);
        bg.setScale(-2, 2 * GameVars.scaleY);

        let midContainer = new Phaser.GameObjects.Container(this.scene, GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT / 2 + 32);
        midContainer.scaleY = GameVars.scaleY;
        this.add(midContainer);

        this.effectLeft = new Phaser.GameObjects.Sprite(this.scene, -340, 0, "texture_atlas_1", "background_light_effect_" + GameConstants.COLOURS[GameVars.level % 3]);
        this.effectLeft.setScale(2);
        this.effectLeft.alpha = 0;
        midContainer.add(this.effectLeft);

        this.effectRight = new Phaser.GameObjects.Sprite(this.scene, 340, 0, "texture_atlas_1", "background_light_effect_" + GameConstants.COLOURS[GameVars.level % 3]);
        this.effectRight.setScale(-2, 2);
        this.effectRight.alpha = 0;
        midContainer.add(this.effectRight);
    }

    public showEffect(length: number): void {

        this.effectLeft.setFrame("background_light_effect_" + GameConstants.COLOURS[GameVars.level % 3]);
        this.effectRight.setFrame("background_light_effect_" + GameConstants.COLOURS[GameVars.level % 3]);

        this.effectLeft.alpha = 1;
        this.effectRight.alpha = 1;

        this.scene.tweens.add({
            targets: [this.effectLeft, this.effectRight],
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.In,
            duration: 500 * length
        });
    }
}
