import { TetrominoShadow } from "./TetrominoShadow";
import { GameConstants } from "./../../../GameConstants";
import { BoardManager } from "../BoardManager";
import { GameVars } from "../../../GameVars";
import { Block } from "./Block";

export class Tetromino extends Phaser.GameObjects.Container {

    public static mobileDown: boolean;

    public static offsets = {
        0 : [[0, -1], [0, 0], [0, 1], [1, 1]], // L
        1 : [[0, -1], [0, 0], [0, 1], [-1, 1]], // J
        2 : [[-1, 0], [0, 0], [1, 0], [2, 0]], // I
        3 : [[-1, -1], [0, -1], [0, 0], [-1, 0]], // 0
        4 : [[-1, 0], [0, 0], [0, -1], [1, -1]], // S
        5 : [[-1, 0], [0, 0], [1, 0], [0, 1]], // T
        6 : [[-1, -1], [0, -1], [0, 0], [1, 0]] // Z
    };

    public static y_start = {
        0 : 1,
        1 : 1,
        2 : 0,
        3 : 1,
        4 : 1,
        5 : 0,
        6 : 1
    };

    public static move_offsets = {
        "left" : [-1, 0],
        "down" : [0, 1],
        "right" : [1, 0]
    };

    private static spaceEnabled: boolean;

    public shape: number;
    public blocks: Block[];
    public cells: {x: number, y: number}[];
    public center: {x: number, y: number};
    public specialPos: number[];
    public blocksContainer: Phaser.GameObjects.Container;
    public tetrominoShadow: TetrominoShadow;

    private keyLeft: Phaser.Input.Keyboard.Key;
    private keyRight: Phaser.Input.Keyboard.Key;
    private keyDown: Phaser.Input.Keyboard.Key;
    private keyUp: Phaser.Input.Keyboard.Key;
    private keyA: Phaser.Input.Keyboard.Key;
    private keyD: Phaser.Input.Keyboard.Key;
    private keyS: Phaser.Input.Keyboard.Key;
    private keyW: Phaser.Input.Keyboard.Key;
    private keySpace: Phaser.Input.Keyboard.Key;
    private fLeft: number;
    private fRight: number;
    private fDown: number;
    private fUp: number;

    private fMax: number;

    private prevPosition: {x: number, y: number};

    constructor(scene: Phaser.Scene, initialX: number, initialY: number, specialBlock: boolean) {
        
        super(scene);

        this.setInputs();

        this.shape =  GameVars.nextTetrominoShapes[0];
        this.blocks = [];
        this.cells = [];

        this.specialPos = [];

        const initX = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_WIDTH) / 2;
        const initY = - (GameConstants.BOARD_CELL_SIZE * GameConstants.BOARD_HEIGHT) / 2;

        this.x = initX + GameConstants.BOARD_CELL_SIZE * (initialX + .5);
        this.y = initY + GameConstants.BOARD_CELL_SIZE * (initialY + Tetromino.y_start[this.shape] + .5);

        this.center = {x: initialX, y: initialY + Tetromino.y_start[this.shape]};

        this.tetrominoShadow = new TetrominoShadow(this.scene);
        this.add(this.tetrominoShadow);

        this.blocksContainer = new Phaser.GameObjects.Container(this.scene);
        this.blocksContainer.setScale(.95);
        this.add(this.blocksContainer);

        for (let i = 0; i < 4; i++) {

            var x = Tetromino.offsets[this.shape][i][0];
            var y = Tetromino.offsets[this.shape][i][1];

            let block = new Block(this.scene, x, y, x * GameConstants.BOARD_CELL_SIZE, y * GameConstants.BOARD_CELL_SIZE, this.shape * 5);
            this.blocksContainer.add(block);
            this.blocks.push(block);
            this.cells.push({x: x + initialX, y: y + initialY + Tetromino.y_start[this.shape]});
        }

        if (specialBlock) {

            let pos = Math.floor(Math.random() * 4);

            this.specialPos.push(pos);
            this.blocks[pos].setSpecial();

            if (Math.random() < GameConstants.DOUBLE_STAR_PERCENTAGE) {

                let newPos =  Math.floor(Math.random() * 4);
                let count = 0;

                var a = Tetromino.offsets[this.shape][pos][0] - Tetromino.offsets[this.shape][newPos][0];
                var b = Tetromino.offsets[this.shape][pos][1] - Tetromino.offsets[this.shape][newPos][1];

                var dis = Math.sqrt(a * a + b * b);

                while ((newPos === pos || dis <= 1) && count < 10) {

                    count++;
                    newPos =  Math.floor(Math.random() * 4);

                    a = Tetromino.offsets[this.shape][pos][0] - Tetromino.offsets[this.shape][newPos][0];
                    b = Tetromino.offsets[this.shape][pos][1] - Tetromino.offsets[this.shape][newPos][1];

                    dis = Math.sqrt(a * a + b * b);
                }

                if (count !== 10) {
                    this.specialPos.push(newPos);
                    this.blocks[newPos].setSpecial();
                }
            }
        }

        this.tetrominoShadow.updatePosition(this);
    }

    public update(): void {

        if (GameVars.isDesktop) {
            this.updateDesktop();
        } else {
            this.updateMobile();
        }
    }

    public updateDesktop(): void {
        if (this.keyLeft.isDown || this.keyA.isDown) {
            this.fLeft++;
            if (this.fLeft > this.fMax && BoardManager.canMoveTetromino("left")) {
                BoardManager.moveTetromino("left");
                this.fLeft = 0;
            }
        } else if (this.keyLeft.isUp || this.keyA.isUp) {
            this.fLeft = this.fMax;
        }
        
        if (this.keyRight.isDown || this.keyD.isDown) {
            this.fRight ++;
            if (this.fRight > this.fMax && BoardManager.canMoveTetromino("right")) {
                BoardManager.moveTetromino("right");
                this.fRight = 0;
            }
        } else if (this.keyRight.isUp || this.keyD.isUp) {
            this.fRight = this.fMax;
        }  
        
        if (this.keyDown.isDown || this.keyS.isDown) {
            this.fDown ++;
            if (this.fDown > this.fMax && BoardManager.canMoveTetromino("down")) {
                BoardManager.moveTetromino("down");
                this.fDown = 0;
            }
        } else if (this.keyDown.isUp || this.keyS.isUp) {
            this.fDown = this.fMax;
        }  

        if (this.keyUp.isDown || this.keyW.isDown) {
            this.fUp ++;
            if (this.fUp > this.fMax * 2) {
                if (BoardManager.canRotateTetromino("clockwise", this.shape)) {
                    BoardManager.rotateTetromino("clockwise", this.shape);
                } else {

                    let isRotated = false;

                    if (BoardManager.canMoveTetromino("left")) {
                        BoardManager.moveTetromino("left");
                        if (BoardManager.canRotateTetromino("clockwise", this.shape)) {
                            isRotated = true;
                            BoardManager.rotateTetromino("clockwise", this.shape);
                        } else {
                            if (BoardManager.canMoveTetromino("left")) {
                                BoardManager.moveTetromino("left");
                                if (BoardManager.canRotateTetromino("clockwise", this.shape)) {
                                    isRotated = true;
                                    BoardManager.rotateTetromino("clockwise", this.shape);
                                } else {
                                    BoardManager.moveTetromino("right");     
                                    BoardManager.moveTetromino("right");                             
                                }
                            } else {
                                BoardManager.moveTetromino("right");  
                            }       
                        }
                    } 
                    
                    if (!isRotated && BoardManager.canMoveTetromino("right")) {
                        BoardManager.moveTetromino("right");
                        if (BoardManager.canRotateTetromino("clockwise", this.shape)) {
                            isRotated = true;
                            BoardManager.rotateTetromino("clockwise", this.shape);
                        } else {
                            if (BoardManager.canMoveTetromino("right")) {
                                BoardManager.moveTetromino("right");
                                if (BoardManager.canRotateTetromino("clockwise", this.shape)) {
                                    isRotated = true;
                                    BoardManager.rotateTetromino("clockwise", this.shape);
                                } else {
                                    BoardManager.moveTetromino("left");     
                                    BoardManager.moveTetromino("left");                             
                                }
                            } else {
                                BoardManager.moveTetromino("left");  
                            }         
                        }
                    } 
                    
                }
                this.fUp = 0;
            }
        } else if (this.keyUp.isUp  || this.keyW.isUp) {
            this.fUp = this.fMax * 2;
        } 

        if (this.keySpace.isDown && Tetromino.spaceEnabled) {
            Tetromino.spaceEnabled = false;
            BoardManager.moveTetrominoToEnd();
        } else if (this.keySpace.isUp) {
            Tetromino.spaceEnabled = true;
        }  
    }

    public updateMobile(): void {

        if (Tetromino.mobileDown && this.scene) {

            if (this.prevPosition ) {
                if (Math.abs(this.prevPosition.x - this.scene.input.activePointer.position.x) > GameConstants.BOARD_CELL_SIZE) {
                    if (this.scene.input.activePointer.position.x < this.prevPosition.x) {
                        if (BoardManager.canMoveTetromino("left")) {
                            BoardManager.moveTetromino("left");
                        }
                    } else {
                        if (BoardManager.canMoveTetromino("right")) {
                            BoardManager.moveTetromino("right");
                        }
                    }
                    this.prevPosition = {x: this.scene.input.activePointer.position.x, y: this.scene.input.activePointer.position.y};
                }

                if (Math.abs(this.prevPosition.y - this.scene.input.activePointer.position.y) > GameConstants.BOARD_CELL_SIZE) {
                    if (this.scene.input.activePointer.position.y > this.prevPosition.y) {
                        if (BoardManager.canMoveTetromino("down")) {
                            BoardManager.moveTetromino("down");
                        }
                    }
                    this.prevPosition = {x: this.scene.input.activePointer.position.x, y: this.scene.input.activePointer.position.y};
                }
            } else {
                this.prevPosition = {x: this.scene.input.activePointer.position.x, y: this.scene.input.activePointer.position.y};
            }
            
        } else {
            this.prevPosition = null;
        }
    }

    public updateShadow(): void {

        this.tetrominoShadow.updatePosition(this);
    }

    public destroy(): void {

        this.keyA.enabled = false;
        this.keyD.enabled = false;
        this.keyS.enabled = false;
        this.keyW.enabled = false;
        this.keySpace.enabled = false;

        super.destroy();
    }

    private setInputs(): void {

        if (Tetromino.spaceEnabled === undefined) {
            Tetromino.spaceEnabled = true;
        }

        this.keyLeft = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.LEFT);
        this.keyRight = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.RIGHT);
        this.keyDown = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.DOWN);
        this.keyUp = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.UP);
        this.keyA = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
        this.keyD = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);
        this.keyS = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S);
        this.keyW = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W);
        this.keySpace = this.scene.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
        
        this.fLeft = 0;
        this.fRight = 0;
        this.fDown = 0;
        this.fUp = 0;

        this.fMax = 8;

        this.keyA.enabled = true;
        this.keyD.enabled = true;
        this.keyS.enabled = true;
        this.keyW.enabled = true;
        this.keySpace.enabled = true;
    }
}
